<template>
  <div class="top barging-container">
    <div class="flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-8">
      <tabs :tabs="tabsList" @updateIndex="updateIndex" :currentIndex="upIndex"></tabs>
    </div>
    <!-- <div style="padding:20px 0px; background-color: white; border-radius: 10px;"> -->
    <div class="barging-card" style="padding:0px 0px; margin-top:18px; background-color:#f2f7fa; border-radius: 10px;">
      <div  v-if="upIndex != '2'" class="flex flex-row justify-end pr-2 pl-2 mb-2 pt-2">
        <div class="flex gap-x-4 text-right">
          <el-input class="Comipt" size="mini" :placeholder="$t('btn.search')" v-model="searchValue"
            @keyup.enter.native="getList"
          >
            <span slot="suffix" @click="getList" style="padding-top: 9px">
              <svg-icon iconClass="search" />
            </span>
          </el-input>
          <el-select size="mini" class="Pendingselect" :clearable="true"
            v-model="selectDate" @change="handleSelectChange" :placeholder="$t('btn.Today')"
          >
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div v-if="upIndex == '2'" class="w-full searchBar">
        <div class="flex flex-wrap justify-end">
          <div class="flex flex-row py-2 pr-2">
            <el-input v-model="params.searchValue" size="mini" clearable placeholder="Search" :style="{ width: '100%' }"></el-input>
          </div>
          <div class="flex flex-row py-2 pr-2">
            <el-date-picker v-model="params.startDate" size="mini" placeholder="Start Date" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2">
            <el-date-picker v-model="params.endDate" size="mini" placeholder="End Date" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="flex flex-row pl-2 py-2">
            <el-button size="mini" type="primary" @click="getList">Search</el-button>
            <el-button size="mini" type="primary" @click="handleExportPDF" :loading="isLoadingExportPdf">Export PDF</el-button>
          </div>
        </div>
      </div>

      <div class="box-card barging-table" v-bind:class="{'mobile-view':isMobileScreen}">
        <div class="w-full Comtable" v-bind:class="{ completedTable: upIndex === '2' }">
          <el-table v-if="upIndex != '2'" key="tableCOQLink"
            :data="listData"
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }"
            :row-style="{
              height: screenType.isMobileScreen? '30px':'64px',
            }"
            :cell-style="getCellStyle"
            @row-click="handleClick"
            table-layout="auto"
            height="100%"
          >
            <el-table-column
              align="center"
              prop="originalName"
              :label="$t('common.FileName')"
              min-width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :prop="upIndex == 0 ? 'createTime' : 'updateTime'"
              :label="upIndex == 0 ? 'Date Created' : 'Date Linked'"
              min-width="100"
            >
            </el-table-column>
          </el-table>

          <el-table v-else key="tableCOQCompleted" 
            :data="listData"
            :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }"
            
            :cell-style="getCellStyle"
            @row-click="handleClick"
            table-layout="auto"
            height="100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column align="center" prop="bid" label="ID" min-width="100" sortable></el-table-column>
            <el-table-column align="center" prop="originalName" label="File Name" min-width="200" sortable></el-table-column>
            <el-table-column align="center" prop="bargeName" label="Barge Name" min-width="200" sortable></el-table-column>
            <el-table-column align="center" prop="companyName" label="Company Name" min-width="200" sortable></el-table-column>
            <el-table-column align="center" prop="date" label="Date Time" min-width="200" :formatter="formatCompleteTime" sortable></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="barging-pagination">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="pageParams.pageNum"
          :limit.sync="pageParams.pageSize"
          :pageSizes="[20, 30, 50, 100]"
          @pagination="getList"
        ></pagination>
      </div>
    </div>
    <pdf-view
      title="Certificate Of Quantity"
      :pdfUrl="operationData.src"
      v-model="operationData.show"
    />
    <el-dialog title="Export PDF" :visible.sync="fetchPdfFailedDialog" @close="handleCloseFetchPdfFailedDialog" :width="isMobileScreen ? '100%' : '500px'">
      <div v-if="fetchPdfSuccessId.length > 0">
        <p class="pb-2">Success to process file for ID below:</p>
        <p>{{ fetchPdfSuccessId.join(', ') }}</p>
      </div>
      <br />
      <div  v-if="fetchPdfFailedId.length > 0">
        <p class="pb-2">Failed to process file for ID below, please check if the file available:</p>
        <p>{{ fetchPdfFailedId.join(', ') }}</p>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button plain type="primary" size="mini" @click="handleCloseFetchPdfFailedDialog">{{ $t("btn.close") }}</el-button>
        </div>
      </template>
    </el-dialog>   
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import { getCoqMangeListList, getCompletedCoqList, downloadFile, getPilotExcelList } from "@/services";
import { getQuantityNum } from "@/services/user";
import PdfView from "@/components/common/pdf-view.vue";
import { mapState } from "vuex";
import { PDFDocument } from 'pdf-lib'
export default {
  name: "requests",
  components: { tabs, PdfView },

  data() {
    return {
      tabsList: [
        { label: "Unlink", count: 0, index: "0" },
        { label: "Linked", count: 0, index: "1" },
        { label: "Completed", count: 0, index: "2" },
      ],
      upIndex: 0,
      listData: [],
      searchValue: "",
      options: [
        {
          value: "1",
          label: "Today",
        },
        {
          value: "2",
          label: "ThisWeek",
        },
        {
          value: "3",
          label: "ThisMonth",
        },
        {
          value: "4",
          label: "ThisYear",
        },
      ],
      selectDate: "",
      pageParams: {
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      operationData: {
        src: "",
        show: false,
      },
      selectedRows: [],
      fetchPdfFailedDialog: false,
      fetchPdfFailedId:[],
      fetchPdfSuccessId:[],
      startDate: "",
      endDate: "",
      params: {
        searchValue: "",
        pageNum: 1,
        pageSize: 20,
        startDate: null,
        endDate: null,
      },
      isLoadingExportPdf: false,
      completedSearchPlaceholder: "Search Barge"
    };
  },
  computed: {
    ...mapState(["userInfo","screenType"]),
    isMobileScreen(){
      return this.screenType?.isMobileScreen??false;
    }
  },
  created() {
    this.handleRoleAccess();
    this.getList();
  },
  methods: {
    updateIndex(index) {
      this.upIndex = index;
      this.getList();
    },
    handleSelectChange() {
      this.getList();
    },
    getList() {
      let data = {
        ...this.pageParams,
        status: this.upIndex,
      };
      if (this.selectDate) {
        data["searchDateType"] = this.selectDate;
      }
      if (this.searchValue) {
        data["originalName"] = this.searchValue;
        this.searchValue = "";
      }
      getQuantityNum().then((res) => {
        this.tabsList = [
          { label: "Unlink", count: res.unLink, index: "0" },
          { label: "Linked", count: res.linked, index: "1" },
          { label: "Completed", count: res.completed, index: "2" },
        ];
        this.handleRoleAccess();
      });
      
      if(data.status == '2'){
        this.params.pageNum = this.pageParams.pageNum
        this.params.pageSize = this.pageParams.pageSize
        getCompletedCoqList(this.params).then((res) => {
          this.listData = res.data.table.rows;
          this.total = res.data.table.total;
        })
      }else{
        getCoqMangeListList(data).then((res) => {
          this.listData = res.rows;
          this.total = res.total;
        });
      }
    },
    handleRoleAccess(){
      // customer admin (4), customer (5), barge (8), barge operator (9) can only view completed tab
      if([4, 5, 8, 9].includes(Number(this.userInfo.role))){
        this.tabsList = this.tabsList.filter(tab => tab.label !== "Linked" && tab.label !== "Unlink");
        this.upIndex = 2;
      }
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleClick(row) {
      this.operationData = {
        src: process.env.VUE_APP_BASE_API + row.url,
        show: true,
      };
    },
    handleSelectionChange(val){
      this.selectedRows = val;
    },
    async handleExportPDF() {
      this.isLoadingExportPdf = true;
      this.fetchPdfSuccessId = [];
      this.fetchPdfFailedId = [];
      const pdfUrls = this.selectedRows.map(row => ({
        id: row.bid,
        editedUrl: process.env.VUE_APP_BASE_API + row.url
      }));

      const mergedPdf = await PDFDocument.create();
      for (const pdf of pdfUrls) {
        var url = pdf.editedUrl
        try {
          const pdfBytes = await fetch(url).then(res => {
            if (!res.ok) {
              throw new Error(`Failed to fetch PDF from ${url}`);
            }
            return res.arrayBuffer();
          });
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
          this.fetchPdfSuccessId.push(pdf.id);
        } catch (error) {
          this.fetchPdfFailedId.push(pdf.id);
        }
      }
      var mergedBlob = await mergedPdf.save();
      if(mergedBlob && this.fetchPdfFailedId.length !== pdfUrls.length){
        const pdfname = "compiled-certificate-of-quality-.pdf";
        const now = new Date();
        const localDate = now.toLocaleDateString('en-CA'); 
        const finalFilename = `${pdfname.split('.')[0]}_${localDate}.pdf`;
        downloadFile(mergedBlob,finalFilename)
      }
      
      this.fetchPdfFailedDialog = true;
    },
    handleCloseFetchPdfFailedDialog(){
      this.isLoadingExportPdf = false;
      this.fetchPdfFailedDialog = false;
    },
    formatCompleteTime(row, column, cellValue){
      if (!cellValue) return ''; 
      return cellValue.replace('T', ' ');
    }
  },
};
</script>

<style scoped lang="scss">
.Topinp {
  position: relative;
}
.Topinput {
  display: flex;
  position: absolute;
  top: -65px;
  right: -40px;
  .Comipt {
    width: 300px;
    height: 46px;
  }
  .Pendingselect {
    width: 221px;
    height: 46px;
    margin-left: 16px;
  }
}
.Reqcol {
  display: flex;
  justify-content: center;
  height: calc(90vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
  .ReqTable {
    width: 1000px;
  }
  .el-button--default {
    color: #fff;
    background-color: #008CDB;
  }
  .el-button--default.is-plain:hover {
    color: #fff;
    background: #008CDB;
    border-color: #008CDB;
  }
  .el-button--default:focus {
    color: #fff !important;
    background: #008CDB !important;
    border-color: #008CDB;
  }
}
::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}
::v-deep .pagination-container {
  background: #f2f7fa;
}
::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
::v-deep .el-table .cell {
  word-break:normal;
}

@media (max-width: 1023px) {
  .Comtable {
    height: calc(100vh - 16rem);
  }
  .completedTable {
      height: calc(100vh - 18.5rem);
  }
  .mobile-view{
    .Comtable {
      height: calc(100vh - 15.5rem);
    }
    .completedTable {
      height: calc(100vh - 370px);
    }
  }
}
@media (min-width: 1024px) {
  .Comtable {
    height: calc(100vh - 14rem);
  }
  .barging-card{
    margin-top: 0px !important;
  }
}
@media (min-width: 1441px) {
  .barging-card{
    height: calc(100vh - 160px);
  }
  .Comtable {
    height: calc(100vh - 15rem);
  }
}
.searchBar{
  width: 100% !important;
  background-color: #f3f7fa;
  // border-radius: 10px;
  // border: 1px solid #D5DDED;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';
  padding: 0px 20px 0px 0px;

  .label {
    padding: 5px;
    color: #1D1B20;
    font-weight: 600;
    text-align: right;
    width: 90px;
  }
}
</style>
